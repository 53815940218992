import React from "react";
import styled from "styled-components";
import { HeadingS } from "../css/typography";
import { GetStaticProps } from "next";
import { getStaticPageOrganization } from "../lib/api/organization";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { defaultLocale } from "../lib/language";

const PageWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const Error404: React.FC = () => {
    return (
        <PageWrapper>
            <HeadingS>404 - Page not found</HeadingS>
        </PageWrapper>
    );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
    const organization = await getStaticPageOrganization();

    return {
        props: {
            organization,
            ...(await serverSideTranslations(locale ?? defaultLocale, ["common"])),
        },
    };
};

export default Error404;
